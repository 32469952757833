<template>
  <div class='base-selector' :class="{ 'has-error': hasError }">
    <label v-if="labelText">{{ labelText }}</label>
    <select ref="selectRef" v-model="selectedValue">
      <option v-for="(option, i) in options" :key="i">{{option}}</option>
    </select>
    <icon-arrow-down :color="hasError ? '#F22C2C' : '#707070'" @click.native="handleArrowClick" />
  </div>
</template>

<script>
import IconArrowDown from '@/components/shared/elements/icons/IconArrowDown';

export default {
  name: 'BaseSelector',
  props: {
    defaultSelected: {
      type: String,
      required: false,
    },
    labelText: {
      type: String,
      required: false,
    },
    hasError: {
      type: Boolean,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  components: {
    IconArrowDown,
  },
  data: () => ({
    selectedValue: undefined,
  }),
  created() {
    if (this.defaultSelected === undefined || this.defaultSelected.length === 0) {
      this.selectedValue = undefined;
    } else {
      this.selectedValue = this.defaultSelected;
    }
  },
  computed: {},
  watch: {
    defaultSelected(val) {
      if (val === undefined || val.length === 0) {
        this.selectedValue = undefined;
      } else {
        this.selectedValue = val;
      }
    },
    selectedValue(value) {
      this.$emit('selected', value);
    },
  },
  methods: {},
};
</script>

<style lang='scss' scoped>
$inputHeight: 30px;

.base-selector {
  position: relative;
}

.icon-arrow-down {
  position: absolute;
  bottom: 5px;
  right: 10px;
  z-index: 0;
}

select {
  border-radius: $globalBorderRadius;
  border: 2px solid $globalBorderColor;
  background-color: transparent;
  padding: 0 25px 0 17px;
  font-size: 12px;
  width: 100%;
  color: $globalFontColor;
  font-weight: 500;
  transition: $transitionBase;
  height: $inputHeight;
  position: relative;
  z-index: 1;
  appearance: none;

  @media screen and (max-width: $breakpointDownSm) {
    height: calc(#{$inputHeight} + 14px);
    font-size: 15px;
  }

  &:focus {
    outline: none;
    border-color: $primaryBlue;
  }
}

label {
  font-size: 12px;
  font-weight: 500;
  padding: 0 0 0 14px;
  line-height: 1;
  margin: 0 0 4px;

  @media screen and (max-width: $breakpointDownXs) {
    font-size: 15px;
    margin: 0 0 7px;
    padding: 0;
  }
}

.has-error {
  select {
    border-color: $globalErrorColor !important;
  }

  label {
    color: $globalErrorColor !important;
  }
}
</style>
