<template>
  <div id='profile-screen' ref="profileScreen">
    <div class="container webshop-container">
      <h1 class="title">{{ $t('pages.profile.title') }}</h1>

      <div class="content-wrapper">
        <error-toast
          :isVisible="toast.isVisible"
          :text="toast.text"
          :type="toast.type"
        />
        <section class="form-section">
          <h1 class="title">{{ $t(personalSection.sectionTitle) }}</h1>
          <div class="inputs-wrapper">
            <base-input
              v-for="(input, i) in personalSection.inputs" :key="input.key"
              :defaultValue="input.value"
              :labelText="$t(input.label)"
              :hasError="input.hasError"
              :type="input.type ? input.type : 'text'"
              @input="handleInputs('personal', i, ...arguments)"
            />
          </div>
        </section>
        <section class="form-section">
            <h1 class="title">{{ $t(invoiceSection.sectionTitle) }}</h1>
            <div class="checkboxes-wrapper">
              <base-checkbox
                v-for="(checkbox, i) in invoiceSection.checkboxes" :key="i"
                :label="$t(checkbox.label)"
                :isChecked="checkbox.isChecked"
                @changed="handleCheckboxInput(i, ...arguments)"
              />
            </div>
            <div class="inputs-wrapper">
              <template v-for="(input, i) in invoiceSection.inputs" >
                <base-input
                  v-if="isCompanyNameInputVisible(i) && !input.options && isTaxNumberInputVisible(input.key)"
                  :defaultValue="input.value"
                  :labelText="$t(input.label)"
                  :hasError="input.hasError"
                  :key="input.key"
                  :type="input.type ? input.type : 'text'"
                  @input="handleInputs('invoice', i, ...arguments)"
                />
                <base-selector
                  v-else-if="input.options"
                  :options="input.options"
                  :defaultSelected="input.value"
                  :key="input.key"
                  :labelText="$t(input.label)"
                  :hasError="input.hasError"
                  @selected="handleCountrySelect"
                />
              </template>
            </div>
          </section>
          <section class="form-section">
            <h1 class="title">{{ $t(passwordSection.sectionTitle) }}</h1>
            <h4 class="subtitle">{{ $t(passwordSection.sectionSubtitle) }}</h4>
            <div class="inputs-wrapper">
              <base-input
                v-for="(input, i) in passwordSection.inputs" :key="input.key"
                :defaultValue="input.value"
                :labelText="$t(input.label)"
                :hasError="input.hasError"
                :type="input.type ? input.type : 'text'"
                @input="handleInputs('password', i, ...arguments)"
              />
            </div>
          </section>
          <section class="buttons-section">
            <base-button
              :text="$t('pages.profile.buttons.submit')"
              :isSecondary="true"
              :isLoading="isBtnLoading"
              :isDisabled="!isDatasChanged"
              @clicked="handleSubmitBtnClick"
            />
            <span class="delete-profile-btn" @click="handleDeleteBtnClick">
              {{ $t('pages.profile.buttons.delete') }}
            </span>
          </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import store from '@/store';
import scroll from '@/components/shared/mixins/scroll';
import validations from '@/components/shared/mixins/validations';

import BaseInput from '@/components/shared/elements/inputs/BaseInput';
import BaseButton from '@/components/shared/elements/buttons/ButtonBase';
import BaseCheckbox from '@/components/shared/elements/inputs/BaseCheckbox';
import BaseSelector from '@/components/shared/elements/inputs/BaseSelector';

const SimpleModal = () => import('@/components/shared/modules/modals/SimpleModal');

export default {
  name: 'ProfileScreen',
  mixins: [scroll, validations],
  props: {},
  components: {
    BaseInput,
    BaseCheckbox,
    BaseButton,
    BaseSelector,
    'error-toast': () => import('@/components/shared/elements/toasts/ErrorToast'),
  },
  data: () => ({
    isDatasChanged: false,
    isBtnLoading: false,
    toast: {
      isVisible: false,
      text: '',
      type: 'error',
    },
    personalSection: {
      sectionTitle: 'pages.profile.form.sections.personal.title',
      inputs: [
        {
          key: 'firstName',
          label: 'pages.profile.form.sections.personal.labels.firstName',
          value: undefined,
          hasError: false,
        },
        {
          key: 'lastName',
          label: 'pages.profile.form.sections.personal.labels.lastName',
          value: undefined,
          hasError: false,
        },
        {
          key: 'email',
          label: 'pages.profile.form.sections.personal.labels.email',
          value: undefined,
          hasError: false,
        },
        {
          key: 'phoneNumber',
          label: 'pages.profile.form.sections.personal.labels.phoneNumber',
          value: '',
          hasError: false,
        },
      ],
    },
    invoiceSection: {
      sectionTitle: 'pages.profile.form.sections.invoiceData.title',
      checkboxes: [
        {
          label: 'pages.profile.form.sections.invoiceData.checkboxes.personal',
          isChecked: true,
        },
        {
          label: 'pages.profile.form.sections.invoiceData.checkboxes.company',
          isChecked: false,
        },
      ],
      inputs: [
        {
          key: 'companyName',
          required: true,
          label: 'pages.profile.form.sections.invoiceData.labels.companyName',
          value: '',
          hasError: false,
        },
        {
          key: 'taxNumber',
          required: true,
          label: 'pages.profile.form.sections.invoiceData.labels.taxNumber',
          value: undefined,
          hasError: false,
        },
        {
          key: 'country',
          required: true,
          label: 'pages.profile.form.sections.invoiceData.labels.country',
          value: undefined,
          hasError: false,
          options: undefined,
        },
        {
          key: 'cityName',
          required: true,
          label: 'pages.profile.form.sections.invoiceData.labels.city',
          value: undefined,
          hasError: false,
        },
        {
          key: 'zipCode',
          required: true,
          label: 'pages.profile.form.sections.invoiceData.labels.zipCode',
          value: undefined,
          hasError: false,
        },
        {
          key: 'publicSpaceName',
          required: true,
          label: 'pages.profile.form.sections.invoiceData.labels.publicSpaceName',
          value: undefined,
          hasError: false,
        },
        {
          key: 'publicSpaceType',
          required: false,
          label: 'pages.profile.form.sections.invoiceData.labels.publicSpaceType',
          value: '',
          hasError: false,
        },
        {
          key: 'houseNumber',
          required: false,
          label: 'pages.profile.form.sections.invoiceData.labels.houseNumber',
          value: '',
          hasError: false,
        },
        {
          key: 'building',
          required: false,
          label: 'pages.profile.form.sections.invoiceData.labels.building',
          value: '',
          hasError: false,
        },
        {
          key: 'stairwayNumber',
          required: false,
          label: 'pages.profile.form.sections.invoiceData.labels.stairway',
          value: '',
          hasError: false,
        },
        {
          key: 'floor',
          required: false,
          label: 'pages.profile.form.sections.invoiceData.labels.floor',
          value: '',
          hasError: false,
        },
        {
          key: 'door',
          required: false,
          label: 'pages.profile.form.sections.invoiceData.labels.door',
          value: '',
          hasError: false,
        },
      ],
    },
    passwordSection: {
      sectionTitle: 'pages.profile.form.sections.passwords.title',
      sectionSubtitle: 'pages.profile.form.sections.passwords.subtitle',
      inputs: [
        {
          key: 'password',
          type: 'password',
          label: 'pages.profile.form.sections.passwords.labels.password',
          value: undefined,
          hasError: false,
        },
        {
          key: 'passwordAgain',
          type: 'password',
          label: 'pages.profile.form.sections.passwords.labels.passwordAgain',
          value: undefined,
          hasError: false,
        },
      ],
    },
    hasIncorrectValue: {
      personalSection: [],
      invoiceSection: [],
    },
  }),
  created() {
    this.getCountries().then((resp) => {
      const countryIndex = this.invoiceSection.inputs.findIndex((item) => item.key === 'country');

      this.invoiceSection.inputs[countryIndex].options = resp.data.map((item) => item.name);
    });
  },
  computed: {
    ...mapGetters({
      getLoggedInUserProfile: 'users/getLoggedInUserProfile',
    }),
    selectedInvoiceDataFromStore() {
      if (this.getLoggedInUserProfile.invoiceData && this.invoiceSection.checkboxes[0].isChecked) { // personal
        return this.getLoggedInUserProfile.invoiceData.find((obj) => !obj.isCompany);
      }

      return this.getLoggedInUserProfile.invoiceData.find((obj) => obj.isCompany); // company
    },
    hasNewPassword() {
      return this.passwordSection.inputs.filter((input) => input.value !== undefined && input.value.length !== 0).length > 0;
    },
  },
  methods: {
    ...mapActions({
      updateUserProfile: 'users/updateUserProfile',
      deleteUserProfile: 'users/deleteUserProfile',
      logout: 'users/logout',
      getCountries: 'settings/getCountries',
    }),
    loadPersonalInputsFromStore() {
      if (this.getLoggedInUserProfile) {
        this.personalSection.inputs.find((item) => item.key === 'firstName').value = this.getLoggedInUserProfile.firstName;
        this.personalSection.inputs.find((item) => item.key === 'lastName').value = this.getLoggedInUserProfile.lastName;
        this.personalSection.inputs.find((item) => item.key === 'email').value = this.getLoggedInUserProfile.email;
        this.personalSection.inputs.find((item) => item.key === 'phoneNumber').value = this.getLoggedInUserProfile.phoneNumber;
      }
    },
    fillInvoiceInputs(invoiceDataObject) {
      this.invoiceSection.inputs.find((item) => item.key === 'companyName').value = invoiceDataObject.companyName;
      this.invoiceSection.inputs.find((item) => item.key === 'taxNumber').value = invoiceDataObject.taxNumber;
      this.invoiceSection.inputs.find((item) => item.key === 'country').value = invoiceDataObject.country;
      this.invoiceSection.inputs.find((item) => item.key === 'cityName').value = invoiceDataObject.cityName;
      this.invoiceSection.inputs.find((item) => item.key === 'zipCode').value = invoiceDataObject.zipCode;
      this.invoiceSection.inputs.find((item) => item.key === 'publicSpaceName').value = invoiceDataObject.publicSpaceName;
      this.invoiceSection.inputs.find((item) => item.key === 'publicSpaceType').value = invoiceDataObject.publicSpaceType;
      this.invoiceSection.inputs.find((item) => item.key === 'houseNumber').value = invoiceDataObject.houseNumber;
      this.invoiceSection.inputs.find((item) => item.key === 'building').value = invoiceDataObject.building;
      this.invoiceSection.inputs.find((item) => item.key === 'stairwayNumber').value = invoiceDataObject.stairwayNumber;
      this.invoiceSection.inputs.find((item) => item.key === 'floor').value = invoiceDataObject.floor;
      this.invoiceSection.inputs.find((item) => item.key === 'door').value = invoiceDataObject.door;
    },
    loadInvoiceDataFromStore() {
      this.invoiceSection.inputs.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.value = undefined;
      });

      if (this.invoiceSection.checkboxes[0].isChecked) { // personal
        const personalInvoiceFromStore = this.getLoggedInUserProfile.invoiceData.find((obj) => !obj.isCompany);
        if (personalInvoiceFromStore !== undefined) {
          this.fillInvoiceInputs(personalInvoiceFromStore);
        }
      } else { // company
        const companyInvoiceFromStore = this.getLoggedInUserProfile.invoiceData.find((obj) => obj.isCompany);
        if (companyInvoiceFromStore !== undefined) {
          this.fillInvoiceInputs(companyInvoiceFromStore);
        }
      }
    },
    handleInputs(key, index, value) {
      switch (key) {
        case 'personal':
          this.isDatasChanged = this.personalSection.inputs[index].value !== value;
          this.personalSection.inputs[index].value = value;
          break;
        case 'invoice':
          this.isDatasChanged = this.invoiceSection.inputs[index].value !== value;
          this.invoiceSection.inputs[index].value = value;
          break;
        case 'password':
          this.isDatasChanged = this.passwordSection.inputs[index].value !== value;
          this.passwordSection.inputs[index].value = value;
          break;
        default:
          break;
      }
    },
    handleCountrySelect(payload) {
      const countryIndex = this.invoiceSection.inputs.findIndex((item) => item.key === 'country');

      this.isDatasChanged = this.invoiceSection.inputs[countryIndex].value !== payload;
      this.invoiceSection.inputs[countryIndex].value = payload;
    },
    isCompanyNameInputVisible(index) {
      if (index === 0 && this.invoiceSection.checkboxes[1].isChecked) {
        return true;
      } else if (index === 0) {
        return false;
      }

      return true;
    },
    isTaxNumberInputVisible(key) {
      if (key === 'taxNumber' && this.invoiceSection.checkboxes[1].isChecked) {
        return true;
      } else if (key === 'taxNumber') {
        return false;
      }

      return true;
    },
    handleCheckboxInput(index, value) {
      if (value) {
        // eslint-disable-next-line no-param-reassign
        this.invoiceSection.checkboxes.forEach((item) => { item.isChecked = false; });
        this.invoiceSection.checkboxes[index].isChecked = value;
        this.loadInvoiceDataFromStore();
        // eslint-disable-next-line no-param-reassign
        this.invoiceSection.inputs.forEach((item) => { item.hasError = false; });
      }
    },
    showToast(text, type = 'error') {
      this.toast.isVisible = true;
      this.toast.text = text;
      this.toast.type = type;
    },
    validatePassword() {
      const password = this.passwordSection.inputs[0];
      const passwordAgain = this.passwordSection.inputs[1];

      if (password.value !== passwordAgain.value) {
        this.passwordSection.inputs.forEach((item, i) => {
          this.passwordSection.inputs[i].hasError = true;
        });

        this.showToast(this.$t('general.errors.password'));

        return true;
      }

      this.passwordSection.inputs.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.hasError = false;
      });

      return false;
    },
    validateTaxNumberLocale() {
      const taxNumberField = this.invoiceSection.inputs.filter((item) => item.key === 'taxNumber')[0];
      const validationResult = this.validateTaxNumber(taxNumberField.value);

      if (!validationResult) {
        taxNumberField.hasError = true;
        this.showToast(this.$t('general.errors.taxNumber'));
      }

      return validationResult;
    },
    validateForm() {
      this.hasIncorrectValue.personalSection = false;
      this.hasIncorrectValue.invoiceSection = false;

      this.personalSection.inputs.forEach((input, i) => {
        if (input.value === undefined || input.value.length === 0) {
          this.personalSection.inputs[i].hasError = true;
        } else if (input.key === 'phoneNumber') {
          this.personalSection.inputs[i].hasError = !this.validatePhoneNumber(input.value);
          this.hasIncorrectValue.personalSection = this.hasIncorrectValue.personalSection ? true : this.personalSection.inputs[i].hasError;
        } else if (input.key === 'firstName' || input.key === 'lastName') {
          this.personalSection.inputs[i].hasError = !this.validateName(input.value);
          this.hasIncorrectValue.personalSection = this.hasIncorrectValue.personalSection ? true : this.personalSection.inputs[i].hasError;
        } else {
          this.personalSection.inputs[i].hasError = false;
        }
      });

      this.invoiceSection.inputs.forEach((input, i) => {
        if ((i === 0 || input.key === 'taxNumber') && this.invoiceSection.checkboxes[1].isChecked) {
          if (input.value === undefined || input.value.length === 0) {
            this.invoiceSection.inputs[i].hasError = true;
          } else {
            this.invoiceSection.inputs[i].hasError = false;
          }
        } else if ((i === 0 || input.key === 'taxNumber') && !this.invoiceSection.checkboxes[1].isChecked) {
          this.invoiceSection.inputs[i].hasError = false;
        }

        if ((i !== 0 && input.key !== 'taxNumber') && input.required) {
          if (input.value === undefined || input.value.length === 0) {
            this.invoiceSection.inputs[i].hasError = true;
          } else if (input.key === 'zipCode') {
            this.invoiceSection.inputs[i].hasError = !this.validateZipCode(input.value);
            this.hasIncorrectValue.invoiceSection = this.hasIncorrectValue.invoiceSection ? true : this.invoiceSection.inputs[i].hasError;
          } else if (input.key === 'cityName' || input.key === 'publicSpaceName') {
            this.invoiceSection.inputs[i].hasError = !this.validateStringInput(input.value);
            this.hasIncorrectValue.invoiceSection = this.hasIncorrectValue.invoiceSection ? true : this.invoiceSection.inputs[i].hasError;
          } else {
            this.invoiceSection.inputs[i].hasError = false;
          }
        }
      });

      const personalSectionHasError = this.personalSection.inputs.filter((input) => input.hasError).length > 0;
      const invoiceSectionHasError = this.invoiceSection.inputs.filter((input) => input.hasError).length > 0;


      if (personalSectionHasError || invoiceSectionHasError) {
        const errorText = this.hasIncorrectValue.personalSection || this.hasIncorrectValue.invoiceSection
          ? this.$t('general.errors.incorrectValues') : this.$t('general.errors.missingFields');
        this.showToast(errorText);
      }

      let passwordValidationResult = false;

      if (this.hasNewPassword) {
        passwordValidationResult = this.validatePassword();
      } else if (!this.hasNewPassword && this.passwordSection.inputs[0].hasError) {
        this.passwordSection.inputs.forEach((item, i) => {
          this.passwordSection.inputs[i].hasError = false;
        });
      }

      let taxNumberValidationResult = true;
      if (this.invoiceSection.checkboxes[1].isChecked) {
        taxNumberValidationResult = this.validateTaxNumberLocale();
      }

      return !personalSectionHasError && !invoiceSectionHasError && !passwordValidationResult && taxNumberValidationResult;
    },
    parseRequestObject() {
      const requestObject = {};

      this.personalSection.inputs.forEach((input) => {
        if (input.value !== this.getLoggedInUserProfile[input.key]) {
          requestObject[input.key] = input.value;
        }
      });

      this.invoiceSection.inputs.forEach((input) => {
        if (this.selectedInvoiceDataFromStore !== undefined && input.value !== this.selectedInvoiceDataFromStore[input.key]) {
          if (requestObject.invoiceData === undefined) {
            requestObject.invoiceData = {};
            requestObject.invoiceData.isCompany = this.invoiceSection.checkboxes[1].isChecked;
            requestObject.invoiceData.invoiceId = this.selectedInvoiceDataFromStore._id;
          }
          requestObject.invoiceData[input.key] = input.value;
        } else if (this.selectedInvoiceDataFromStore === undefined) {
          if (requestObject.invoiceData === undefined) {
            requestObject.invoiceData = {};
            requestObject.invoiceData.isCompany = this.invoiceSection.checkboxes[1].isChecked;
          }
          requestObject.invoiceData[input.key] = input.value;
        }
      });

      if (this.hasNewPassword) {
        requestObject.password = this.passwordSection.inputs[0].value;
      }

      return requestObject;
    },
    resetPasswordFields() {
      // eslint-disable-next-line no-param-reassign
      this.passwordSection.inputs.forEach((item) => { item.value = undefined; });
    },
    handleSubmitBtnClick() {
      const formValidationResult = this.validateForm();

      if (formValidationResult) {
        const requestObj = this.parseRequestObject();
        this.isBtnLoading = true;

        this.updateUserProfile(requestObj).then((response) => {
          this.toast.isVisible = true;
          this.toast.type = 'success';
          this.toast.text = this.$t('pages.profile.success.toast');
          this.isBtnLoading = false;

          if (requestObj.password) {
            this.resetPasswordFields();
          }

          this.isDatasChanged = false;
          this.scrollToToast('profileScreen');
        }).catch((error) => {
          this.toast.isVisible = true;
          this.toast.type = 'error';
          this.toast.text = error.data.message;
          this.isBtnLoading = false;

          this.scrollToToast('profileScreen');
        });
      } else {
        this.scrollToToast('profileScreen');
      }
    },
    handleDeleteBtnClick() {
      this.$eventBus.showModal({
        bind: {
          is: SimpleModal,
          titleText: this.$t('pages.profile.profileDelete.confirmModal.title'),
          primaryBtnText: this.$t('pages.profile.profileDelete.confirmModal.buttons.confirm'),
          secondaryBtnText: this.$t('pages.profile.profileDelete.confirmModal.buttons.cancel'),
          modalTitleStyle: { margin: '20px 0 0 0' },
          closeable: true,
        },
        on: {
          primaryButtonClicked: () => {
            this.deleteUserProfile().then(() => {
              this.logout().then(() => {
                this.$eventBus.closeModal();
                store.commit('storeCartId', undefined);
                this.$router.push('/');
              });
            });
          },
          secondaryButtonClicked: () => {
            this.$eventBus.closeModal();
          },
        },
      });
    },
  },
  mounted() {
    this.loadPersonalInputsFromStore();
    this.loadInvoiceDataFromStore();

    this.isDatasChanged = false;
  },
};
</script>

<style lang='scss' scoped>
$contentMaxWidth: 610px;
$buttonsWidth: 210px;

#profile-screen {
  padding: 40px 0 50px;
}

.content-wrapper {
  border-radius: $globalBorderRadius;
  background-color: $white;
  box-shadow: $lightDropdownShadow;
  max-width: $contentMaxWidth;
  margin: 0 auto;
  padding: 30px 85px 20px;

  @media screen and (max-width: $breakpointDownSm) {
    padding-left: 55px;
    padding-right: 55px;
  }

  @media screen and (max-width: $breakpointDownXs) {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.title {
  text-transform: uppercase;
  color: $primaryBlue;
  display: block;
  font-size: 40px;
  font-weight: 700;
  margin: 0 0 40px;
  text-align: center;

  @media screen and (max-width: $breakpointDownXs) {
    font-size: 32px;
  }
}


section {
  margin: 0 auto 17px;

  .title {
    text-transform: uppercase;
    font-weight: 700;
    color: $globalFontColor;
    font-size: 15px;
    text-align: left;
    margin: 0 0 5px;

    @media screen and (max-width: $breakpointDownSm) {
      margin: 0 0 8px;
    }
  }

  .subtitle {
    font-size: 12px;
    font-weight: 400;
  }
}

.inputs-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 7px;

  @media screen and (max-width: $breakpointDownXs) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: $breakpointDownXs) {
    row-gap: 8px;
  }
}

.checkboxes-wrapper {
  display: flex;
  margin: 0 0 10px 5px;

  .base-checkbox {
    &:first-child {
      margin: 0 10px 0 0;
    }
  }
}

.buttons-section {
  width: $buttonsWidth;
  margin: 35px auto 0;

  button {
    display: block;
    width: 100%;
    text-transform: uppercase;

    @media screen and (max-width: $breakpointDownXs) {
      height: 45px;
    }
  }
}

.delete-profile-btn {
  display: block;
  text-decoration: underline;
  margin: 15px 0 0;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  transition: $transitionBase;

  &:hover {
    color: $globalErrorColor;
    text-decoration: none;
  }
}

// TODO: refactor
.error-toast {
  margin: 0 0 25px;
}
</style>
