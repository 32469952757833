var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"profileScreen",attrs:{"id":"profile-screen"}},[_c('div',{staticClass:"container webshop-container"},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('pages.profile.title')))]),_c('div',{staticClass:"content-wrapper"},[_c('error-toast',{attrs:{"isVisible":_vm.toast.isVisible,"text":_vm.toast.text,"type":_vm.toast.type}}),_c('section',{staticClass:"form-section"},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t(_vm.personalSection.sectionTitle)))]),_c('div',{staticClass:"inputs-wrapper"},_vm._l((_vm.personalSection.inputs),function(input,i){return _c('base-input',{key:input.key,attrs:{"defaultValue":input.value,"labelText":_vm.$t(input.label),"hasError":input.hasError,"type":input.type ? input.type : 'text'},on:{"input":function($event){
var i$1 = arguments.length, argsArray = Array(i$1);
while ( i$1-- ) argsArray[i$1] = arguments[i$1];
return _vm.handleInputs.apply(void 0, [ 'personal', i ].concat( argsArray ))}}})}),1)]),_c('section',{staticClass:"form-section"},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t(_vm.invoiceSection.sectionTitle)))]),_c('div',{staticClass:"checkboxes-wrapper"},_vm._l((_vm.invoiceSection.checkboxes),function(checkbox,i){return _c('base-checkbox',{key:i,attrs:{"label":_vm.$t(checkbox.label),"isChecked":checkbox.isChecked},on:{"changed":function($event){
var i$1 = arguments.length, argsArray = Array(i$1);
while ( i$1-- ) argsArray[i$1] = arguments[i$1];
return _vm.handleCheckboxInput.apply(void 0, [ i ].concat( argsArray ))}}})}),1),_c('div',{staticClass:"inputs-wrapper"},[_vm._l((_vm.invoiceSection.inputs),function(input,i){return [(_vm.isCompanyNameInputVisible(i) && !input.options && _vm.isTaxNumberInputVisible(input.key))?_c('base-input',{key:input.key,attrs:{"defaultValue":input.value,"labelText":_vm.$t(input.label),"hasError":input.hasError,"type":input.type ? input.type : 'text'},on:{"input":function($event){
var i$1 = arguments.length, argsArray = Array(i$1);
while ( i$1-- ) argsArray[i$1] = arguments[i$1];
return _vm.handleInputs.apply(void 0, [ 'invoice', i ].concat( argsArray ))}}}):(input.options)?_c('base-selector',{key:input.key,attrs:{"options":input.options,"defaultSelected":input.value,"labelText":_vm.$t(input.label),"hasError":input.hasError},on:{"selected":_vm.handleCountrySelect}}):_vm._e()]})],2)]),_c('section',{staticClass:"form-section"},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t(_vm.passwordSection.sectionTitle)))]),_c('h4',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.$t(_vm.passwordSection.sectionSubtitle)))]),_c('div',{staticClass:"inputs-wrapper"},_vm._l((_vm.passwordSection.inputs),function(input,i){return _c('base-input',{key:input.key,attrs:{"defaultValue":input.value,"labelText":_vm.$t(input.label),"hasError":input.hasError,"type":input.type ? input.type : 'text'},on:{"input":function($event){
var i$1 = arguments.length, argsArray = Array(i$1);
while ( i$1-- ) argsArray[i$1] = arguments[i$1];
return _vm.handleInputs.apply(void 0, [ 'password', i ].concat( argsArray ))}}})}),1)]),_c('section',{staticClass:"buttons-section"},[_c('base-button',{attrs:{"text":_vm.$t('pages.profile.buttons.submit'),"isSecondary":true,"isLoading":_vm.isBtnLoading,"isDisabled":!_vm.isDatasChanged},on:{"clicked":_vm.handleSubmitBtnClick}}),_c('span',{staticClass:"delete-profile-btn",on:{"click":_vm.handleDeleteBtnClick}},[_vm._v(" "+_vm._s(_vm.$t('pages.profile.buttons.delete'))+" ")])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }